import { render, staticRenderFns } from "./HeroHomeAnim.vue?vue&type=template&id=542bba1c&scoped=true"
import script from "./HeroHomeAnim.vue?vue&type=script&lang=js"
export * from "./HeroHomeAnim.vue?vue&type=script&lang=js"
import style0 from "./HeroHomeAnim.vue?vue&type=style&index=0&id=542bba1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542bba1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkButton: require('/srv/www/htdocs/techcast-2022-frontend/components/LinkButton.vue').default,VideoComponent: require('/srv/www/htdocs/techcast-2022-frontend/components/VideoComponent.vue').default,LinkButton: require('/srv/www/htdocs/techcast-2022-frontend/components/LinkButton.vue').default})
