
import LinkButton from "./LinkButton.vue";
import VideoComponent from "./VideoComponent.vue";
import lottie from "lottie-web";

export default {
  name: "HeroSectionHomeAnim",
  components: { VideoComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headlineMinimized: false,
    };
  },
  methods: {
    loadLottie(
      reference,
      file,
      initialSegmentStart = 0,
      initialSegmentEnd = 200
    ) {
      // Lottie config instance
      const animation = lottie.loadAnimation({
        container: this.$refs[reference],
        renderer: "svg",
        loop: false,
        autoplay: true,
        path: `${window.location.origin}/lottie/${file}.json`,
        initialSegment: [initialSegmentStart, initialSegmentEnd],
      });

      animation.play();
    },
    minimizeHeadline(fontSize, duration, delay) {
      if (
        !this.headlineMinimized &&
        [...document.getElementsByClassName("hero-anim-headline")].length
      ) {
        this.$gsap.from(".hero-anim-headline", {
          fontSize: fontSize,
          left: "50%",
          transform: "translateX(-25%)",
          duration: duration,
          delay: delay,
        });
        this.headlineMinimized = true;
      }
    },
    showVideo(duration) {
      if ([...document.getElementsByClassName("video-wrapper")].length) {
        this.$gsap.to(".hero-home-anim .video-wrapper", {
          duration: duration,
          opacity: 1,
          visibility: "visible",
        })
      }
    },
    hideVideo() {
      if ([...document.getElementsByClassName("video-wrapper")].length) {
        this.$gsap.to(".hero-home-anim .video-wrapper", {
          opacity: 0,
        });
      }
    },
    hidePlayButton() {
      if ([...document.getElementsByClassName("play-button")].length) {
        this.$gsap.to(".hero-home-anim .play-button", {
          visibility: "hidden",
          opacity: 0,
        });
      }
    },
    showPlayButton(opacityDuration, scaleDuration) {
      if ([...document.getElementsByClassName("play-button")].length) {
        this.$gsap.to(".hero-home-anim .play-button", {
          visibility: "visible",
          opacity: 1,
          duration: opacityDuration,
          onComplete: () => {
            setTimeout(() => {
              this.$gsap.from(".hero-home-anim .play-button", {
                scale: 1.1,
                repeat: 2,
                duration: scaleDuration,
              });
            }, 1500);
          },
        });
      }
    },
    showHeadline(duration) {
      if ([...document.getElementsByClassName("hero-anim-headline")].length) {
        this.$gsap.to(".hero-anim-headline", {
          color: "#000",
          duration: duration,
        });
      }
    },
    showProduktionLink() {
      if ([...document.getElementsByClassName("link-produktion")].length) {
        this.$gsap.to(".link-produktion", {
          display: "block",
        });
      }
    },
    showPlattformLink() {
      if ([...document.getElementsByClassName("link-plattform")].length) {
        this.$gsap.to(".link-plattform", {
          display: "block",
        });
      }
    },
    showInnovationLink() {
      if ([...document.getElementsByClassName("link-innovation")].length) {
        this.$gsap.to(".link-innovation", {
          display: "block",
        });
      }
    },
    autoplayVideo() {
      this.$refs.videoComponent.playVideo();
    },
  },
  mounted() {
    if (this.$store.state.startAnimationDone) {
      this.showHeadline(0);

      this.loadLottie(
        "animation-container-event-produktion",
        "event-produktion",
        200
      );
      this.showProduktionLink();
      this.loadLottie(
        "animation-container-event-plattform",
        "event-plattform",
        200
      );
      this.showPlattformLink();
      this.loadLottie(
        "animation-container-event-innovation",
        "event-innovation",
        200
      );
      this.showInnovationLink();

      this.showVideo(0.8);
      
    } else {
      this.showHeadline(0.8);
      this.hideVideo();
      this.hidePlayButton();

      // call animation depending on screen size
      if (
        parseInt(window.innerWidth) >= 992 &&
        parseInt(window.innerWidth) < 1200
      ) {
        this.minimizeHeadline("60px", 1, 1.2);
        initializeTimeouts.call(this, 2500);
      } else if (
        parseInt(window.innerWidth) >= 1200 &&
        parseInt(window.innerWidth) < 1400
      ) {
        this.minimizeHeadline("75px", 1, 1.2);
        initializeTimeouts.call(this, 2500);
      } else if (parseInt(window.innerWidth) >= 1400) {
        this.minimizeHeadline("90px", 1, 1.2);
        initializeTimeouts.call(this, 2500);
      } else {
        initializeTimeouts.call(this, 0);
      }

      function initializeTimeouts(delay) {
        // Load Lottie animations and the video
        setTimeout(() => {
          // Task 1: Load Lottie animation for event-produktion
          this.loadLottie(
            "animation-container-event-produktion",
            "event-produktion"
          );
          this.showProduktionLink();

          // Task 2: Load Lottie animation for event-plattform
          setTimeout(() => {
            this.loadLottie(
              "animation-container-event-plattform",
              "event-plattform"
            );
            this.showPlattformLink();
          }, 500);

          // Task 3: Load Lottie animation for event-innovation
          setTimeout(() => {
            this.loadLottie(
              "animation-container-event-innovation",
              "event-innovation"
            );
            this.showInnovationLink();
          }, 1000);

          // Task 4: Show video after 1500ms
          setTimeout(() => {
            this.showVideo(0.8);
            this.autoplayVideo();
          }, 3090);
          
        }, delay);
      }

      this.$store.commit("startAnimationDone", true);
    }
  },
  beforeDestroy() {},
};
